import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';

import {
  AboutWrapper,
  AboutPageTitle,
  QaWrapper,
} from './style';

// import styles from './index.module.css';
import { user, titleimg, faqblock, answer, faqleft, faqright, faqblockline, cp_actab, cp_plus, cp_actabcontent, detailsdiv, sectionheader} from './index.module.css';


const User = props => (
      <div className={user}>
        {/* <img src={props.titleimg} className={titleimg} alt="" /> */}


        <div className={faqblock}>
        <details style={{transition:"1s"}}>
          <summary className={props.question}>
            {props.question}
          </summary>

            <div>
              回答
            <p className={answer}>{props.answer}</p>
            </div>
        <Link to={props.url}>
          関連資料
        </Link>
        </details>
        </div>
      </div>
  )

  const User3 = props => (
  <div className={user}>
    <details style={{transition:"1s"}}>
      <summary className={props.question}>
        {props.question}
      </summary>
        回答：
        <p className={answer}>{props.answer}</p>
    </details>
  </div>
  )
  
  const User2 = props => (
  <QaWrapper>
    <details>
      <summary>
        {props.question}
      </summary>
      <div>
        回答：
        <p>{props.answer}</p>
      </div>
    </details>
  </QaWrapper>
  )
  

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      titleimg: file(absolutePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1770, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  return (
    <AboutWrapper>
      
      <AboutPageTitle>
        <h2>よくあるご質問とその回答</h2>
        <p>
          このページでは、よくある質問とその回答をご紹介します。
        </p>
      </AboutPageTitle>

{/*  */}

  <div className={sectionheader}>
    テータについて
  </div>

  <User2 url="/sdgs" question="	メッシュデータとは何ですか？	  " answer=	"　メッシュデータにつきましては、以下に示します「総務省統計局」のウェブサイトが参考になります。
　https://www.stat.go.jp/data/mesh/m_tuite.html" />
  <User2 url="/sdgs" question="	３次元グラフが示しているデータの、出典を教えてください。	  " answer="	　当サイトでは、３次元グラフを描画するページの下方に、「データの詳細情報を確認する」コーナーを設けており、そちらにデータの出典を記載しております。	" />
  <User2 url="/sdgs" question="	各メッシュのデータの具体的な数値が知りたいのですが。	  " answer="	　現在、当サイトで用いているデータは、国勢調査など公的な機関で公開されているものとなります。当サイトでは、３次元グラフを描画するページの下方に、「データの詳細情報を確認する」コーナーを設けており、そちらにデータの出典を記載いたしました。具体的な数値は、そちらの出典元にてご確認ください。	" />
  <User2 url="/sdgs" question="	分析をより深めるためにはどうしたらよいですか？	  " answer="	　メッシュ統計データは、様々な機関で整備と公開が進められております。当サイトの３次元グラフを描画するページの下方に、「データの詳細情報を確認する」コーナーがあり、そちらの外部リンクが一つの参考になります。	" />
  <User2 url="/sdgs" question="	３次元グラフが描画されている画面の、背景の地図の出典を教えてください。	  " answer="　国土地理院から提供されているものになります。
　詳しくは、以下の「地理院タイル」のサイトをご覧ください。
　https://maps.gsi.go.jp/development/ichiran.html" />

<div className={sectionheader}>
    操作について
  </div>

  <User2 url="/sdgs" question="	「場所を確認する」ボタンを押しても、だいぶ離れた場所が表示されるのですが。	  " answer="　こちらの機能は、視点の位置の直下の座標をGoogleマップで呼び出すものとなっております。
　気になる3次元グラフがあったら、視点をグラフの天井に近づけて真下を向き、地図画面内の右端の＋ボタンをクリックしてみてください。すると、視点が柱の天井を越えて内側に入り、真下方向に地面の画像が見えるようになります。その状態で「場所を確認する」ボタンを押してみてください。" />
  <User2 url="/sdgs" question="	各メッシュの範囲がどのような地域か、もっとスムーズに見られるとよいのですが。	  " answer="　「GoogleEarthで表示」ボタンをご活用いただくことで、よりスムーズな把握が可能になります。
　こちらから、描画した３次元グラフをKMLファイルでダウンロードし、GoogleEarthPro上で呼び出すことができます。GoogleEarthProでは、各メッシュへ視点を近づけることで、その地点のGoogleストリートビューを見られます（バージョン:7.3.3.7786 64-bit の時点)。" />
  <User2 url="/sdgs" question="	昔と今の３次元グラフを、一つの画面上で同時に描画させたいのですが。	  " answer="　当サイトで可能です。
　当サイトでは、３次元グラフを描画させるページ内の上側に、「地域の昔と比べる」タブがございます。これにより、同じ自治体の異なる年次のデータを左右で比較することができます。" />
  <User2 url="/sdgs" question="	二つの市町村の３次元グラフを、一つの画面上で同時に描画させたいのですが。	  " answer="　当サイトで可能です。
　当サイトでは、３次元グラフを描画させるページ内の上側に、「他の地域と比べる」タブがございます。これにより、二つの地域を左右で比較することができます。
　ただし、地続きの二つの自治体をつなげて描画させたい場合は「GoogleEarthで表示」ボタンをご活用いただくことで、可能になります。
当サイトでは、３次元グラフを描画するページ内の右上側に「GoogleEarthで表示」ボタンがあり、各市町村ごとの３Dグラフ描画結果をKMLファイルとしてダウンロードすることができます。それらをGoogleEarthPro上で呼び出し、いずれをもチェックボックスでonにすれば、同一画面上で合わせて描画されることとなります（バージョン:7.3.3.7786 64-bit の時点)。" />
  <User2 url="/sdgs" question="	三つ以上の近隣の市町村の３次元グラフを、一つの画面上で同時に描画させたいのですが。	  " answer="　「GoogleEarthで表示」ボタンをご活用いただくことで、可能になります。
　当サイトでは、３次元グラフを描画するページ内の右上側に「GoogleEarthで表示」ボタンがあり、各市町村ごとの３Dグラフ描画結果をKMLファイルとしてダウンロードすることができます。それらをGoogleEarthPro上で呼び出し、いずれをもチェックボックスでonにすれば、同一画面上で合わせて描画されることとなります（バージョン:7.3.3.7786 64-bit の時点)。" />
  <User2 url="/sdgs" question="	県全体の３次元グラフを、一つの画面上で同時に描画させたいのですが。	  " answer="　当サイトで可能です。
　３次元グラフを描画するページで、「自分で地域を選ぶ」タブが選択されている状態で、市町村名の入力欄に、都道府県名をご入力ください。県全体の描画が一気に開始されます。
　ただし、お使いのパソコンの性能やインターネットの通信環境によっては、描画が遅くなったりフリーズしたりする恐れもございますので、ご留意ください。" />
  <User2 url="/sdgs" question="	日本全国の３次元グラフを、一つの画面上で同時に描画させたいのですが。	  " answer="　当サイトで可能です。
　３次元グラフを描画させるページ内の右上側に、「同じテーマを国土全体で見る」タブがございます。そちらを選ぶと、自動的に日本全国の３次元グラフ描画が開始されます。
　こちらは、描画が重くならないよう、メッシュの大きさを広げてデータを軽くしてあります。" />

<div className={sectionheader}>
    ご利用の際の留意点（著作権など）
  </div>


  <User2 url="/sdgs" question="	このサイトを授業で使ってもよいですか？	  " answer="　はい、自由にお使いいただけます。
　高等学校の新設科目「地理総合」や「地理探究」における、地域を展望したり、国土像を構想したりする学びなどに、ご活用いただければ幸いです。
　また、文部科学省「社会科、地理歴史科、公民科の指導におけるICTの活用について」にて例示されている、“複数資料の重ね合わせ（を活かした地域分析）”に役立つツールとなれば幸いです。" />
  <User2 url="/sdgs" question="	夏休みの課題のテーマに使ってもよいですか？	  " answer="　はい、もちろんお使いいただけます。
　高等学校の新設科目「総合的な探究の時間」などに、ご活用いただければ幸いです。" />
  <User2 url="/sdgs" question="	こちらのサイトの画像を、資料として使ってもよいですか？	  " answer="　教育やまちづくりが目的であれば、自由にお使いいただけます。
　ただし、資料には「地域見える化GISジオグラフ（© 2021 株式会社 帝国書院＋非営利型一般社団法人 都市構造可視化推進機構）」のウェブサイトを参照していることを出典として明示してください。
　教育やまちづくり以外の目的でご利用になりたい場合は、下記お問い合わせフォームにてお問い合わせください。
　https://www.teikokushoin.co.jp/information/index08.php

　なお、Google Earth Pro のソフト上で表示した画像を用いる場合は、Google社による使用条件があります。詳しくは以下をご確認ください。
　　＊Google マップ / Google Earth 追加利用規約
　　　https://www.google.com/intl/ja/help/terms_maps/
　　＊Google マップ / Google Earth の権利帰属表示に関するガイドライン
　　　https://about.google/brand-resource-center/" />
  <User2 url="/sdgs" question="	３次元グラフをGoogleEarthで表示させた際に、背景を地理院地図に替えることはできますか？	  " answer="　可能です。
　国土地理院の地理院地図のサイトからKMLファイルをダウンロードし、GoogleEarthPro上で読み込むと、レイヤーの一つとして重ねることができます。
　詳しくは、以下の「地理院KML」のサイトをご覧ください。
　http://geolib.gsi.go.jp/node/2537
　なお、地理院地図で公開されているKMLファイルを利用する際は，以下をご確認ください。
　　＊国土地理院コンテンツの利用規約
　　　https://www.gsi.go.jp/kikakuchousei/kikakuchousei40182.html
　　＊地理院地図の利用規約
　　　https://maps.gsi.go.jp/help/termsofuse.html" />


<div className={sectionheader}>
    内容の改善に向けて
  </div>


  <User2 url="/sdgs" question="	見られるデータが少ないようですが？	  " answer="	　2022（令和4）年4月には、全国の高等学校で新課程の学びが始まりますが、それまでに順次、SDGsや地理総合・地理探究などのテーマ沿ったデータを、広く整えていきたいと考えております。	" />
  <User2 url="/sdgs" question="	載せてほしいデータがあるのですが？	  " answer="　是非情報をお寄せください。
　当サイトは、500mまたは1㎞メッシュ単位で集計されたメッシュデータであれば、広く取り扱うことができます。学習素材として効果的な、複数のデータの組み合わせ事例がございましたら、下記のお問い合わせフォームにお寄せいただければ幸いでございます。
　https://www.teikokushoin.co.jp/information/index08.php
　その際は、以下の項目を明示いただければ幸いでございます。
　　① 3次元グラフの〈高さ〉で表したいデータ
　　② 3次元グラフの〈色彩〉で表したいデータ
　　③ ①の出典（メッシュデータのウェブサイト上のアドレス）
　　④ ②の出典（メッシュデータのウェブサイト上のアドレス）
　　⑤ ①の出典元が無償公開・有償公開のいずれか
　　⑥ ②の出典元が無償公開・有償公開のいずれか
　　⑦ ①と②を組み合わせるねらい（どのようなことがとらえられるか、あるいは、どのようなことを生徒に学習・探究させたいか）
　なお、当サイトで掲載しているデータ（2021年4月現在）は、全てが公的機関により公開されているデータであり、今後も基本的には公的機関によって公開されたデータが掲載対象となります。
　また、当サイトを支える専門家の方々は、基本的にプロボノ活動等によるご参画になりますため、一つ一つのご提案にお返事するのは難しいこと、何卒ご了承いただければ幸いでございます。" />
  <User2 url="/sdgs" question="	「典型例とその解説」のなかには未掲載のものもあるようですが、地理の授業で事例として示したい地域に心当たりがあります。	  " answer="　是非情報をお寄せください。
　地理の教科書に掲載されていたり、ニュースの話題になったりしているような社会現象が、より鮮明に３次元グラフに現れる地域に心当たりがございましたら、是非、下記のお問い合わせフォームにお寄せいただければ幸いでございます。
　https://www.teikokushoin.co.jp/information/index08.php
　その際は、以下の項目をお示しいただければ幸いでございます。
　　① 当サイトにおけるテーマ種別のテーマ番号
　　② 当サイトにおけるテーマ種別のテーマタイトル文言
　　③ 該当する地域の自治体名（広域なら、隣接する市町村名を複数挙げていただいてかまいません）
　　④ 解説文（該当する地域からどのようなことが読み取れるか）の概要" />
  <User2 url="/sdgs" question="	改善してもらいたい点があるのですが。	  " answer="　是非、下記のお問い合わせフォームにお寄せいただければ幸いでございます。
　https://www.teikokushoin.co.jp/information/index08.php
　なお、当サイトを支える専門家の方々は、基本的にプロボノ活動等によるご参画になりますため、一つ一つのご提案にお返事するのは難しいこと、何卒ご了承いただければ幸いでございます。" />

<div className={sectionheader}>
    当サイトの成り立ち
</div>


  <User2 url="/sdgs" question="	こちらのサイトは、どういった着想でつくられたのですか？	  " answer="　当サイトは、地方自治体の都市計画の現場などで活用されているWebGIS「都市構造可視化計画」を基として、着想されました。
　「都市構造可視化計画」の3次元グラフは、「色」と「高さ」の表現を駆使することで、二つの要素が相互作用しているエリアを強調表現することができます。これを基に、学校向けのユーザーインターフェイスへの改良や、高校地理教育向けのデータの掲載を図ったのが、当サイトです。
　文部科学省「社会科、地理歴史科、公民科の指導におけるICTの活用について」にて例示されている、“複数のデータの重ね合わせ（を活かした地域分析）”に役立つツールとなれば幸いです。
　なお、「都市構造可視化計画」ウェブサイトへのリンクは、下記となります。
　https://mieruka.city/" />
  <User2 url="/sdgs" question="	こちらのサイトは、どのような方々によってつくられたのですか？	  " answer="　当サイトの、学習指導要領に挙げられている内容に即したデータの整備や、ユーザーインターフェイスの改善などは、「都市構造可視化計画」の開発に携わる一般社団法人（非営利型）都市構造可視化推進機構-地理委員会のプロボノ活動等および、神奈川大学の根元一幸特任准教授のご協力により出来上がっております。
　なお、「都市構造可視化推進機構」ウェブサイトへのリンクは下記となります。
　https://kashika.or.jp/" />

















    </AboutWrapper>
  );
};

export default About;
